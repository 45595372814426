import React from 'react';

import { useSelector } from 'react-redux';

import Head from 'next/head';

import _isEqual from 'lodash/isEqual';

import { pageNames } from '@atc/bonnet-paths';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import useIsIndexable from '@/utilities/useIsIndexable';

import { currentPageNameDuck } from '@/ducks';

import { srpNoSearchResultsDuck } from '@/ducks/srp';

import usePageContent from '@/hooks/usePageContent';

import CanonicalUrlModule from '@/modules/CanonicalUrlModule';

import CreditAppScriptLoader from '@/components/CreditAppScriptLoader';
import FacebookMetaTags from '@/components/FacebookMetaTags/index.js';
import FontPreloads from '@/components/FontPreloads/index.js';
import OptimizelySnippetLoader from '@/components/OptimizelySnipperLoader/index.js';
import PixallPreload from '@/components/PixallPreload/index.js';

const { SEARCH_RESULTS, FORD_LANDING, FYC_SITEMAP } = pageNames;

const renderOpenGraphMetaTags = ({ brandName, brandOGImage, brandDefaultMetaDescription, content, currentUrl, openGraphImage }) => {
    const openGraph = {
        title: content.openGraphTitle || brandName,
        type: content.openGraphType || 'website',
        url: currentUrl,
        image: openGraphImage || content.openGraphImage || brandOGImage,
        description: content.openGraphDescription || content.metaDescription || brandDefaultMetaDescription,
        site_name: content.openGraphSiteName || brandName,
    };
    const openGraphTags = Object.keys(openGraph).map((key) => (
        <meta
            key={key}
            property={`og:${key}`}
            content={openGraph[key]}
        />
    ));

    return (
        <Head>
            {openGraphTags}
        </Head>
    );
};

const renderManifestFile = ({ brandName }) => (brandName === 'Autotrader') && (
    <>
        <link
            rel="manifest"
            href="/cars-for-sale/manifest.json"
            crossOrigin="use-credentials"
        />
        <link
            href="/cars-for-sale/assets/atc/img/Autotrader-White-Logo.svg"
            rel="icon"
            type="image/svg"
            sizes="16x16"
        />
        <link
            href="/cars-for-sale/assets/atc/img/Autotrader-Icon-IOS.png"
            rel="icon"
            type="image/png"
            sizes="512x512"
        />
        <link
            rel="apple-touch-icon"
            href="/cars-for-sale/assets/atc/img/Autotrader-Icon-IOS.png"
        />
    </>
);

const renderFavicon = (favicon) => {
    if (favicon) {
        return (
            <link
                rel="icon"
                href={favicon}
            />
        );
    }
    return null;
};

function CommonHeadContainer({
    currentUrl,
    openGraphImage,
    pageName,
    query = {},
}) {

    const { isBrand, brands } = useBrand();
    const currentPageName = useSelector(currentPageNameDuck.selectors.getDuckState);
    const canonicalUrl = useSelector(CanonicalUrlModule.duck.selectors.getDuckState);
    const showNoResults = useSelector(srpNoSearchResultsDuck.selectors.isShowNoResults);

    const currentUrlFormatted = currentUrl && currentUrl.split('?');
    if (currentUrl && (currentPageName === SEARCH_RESULTS || currentPageName === FYC_SITEMAP)) {
        currentUrl = currentUrlFormatted[0];
    }

    const content = usePageContent();

    const {
        brand: [, {
            brand_name: brandName,
            favicon: brandFavicon,
            inventory_image_url: inventoryImageUrl,
            og_image: brandOGImage,
            default_meta_description: brandDefaultMetaDescription,
        }],
    } = useFeatures([
        'brand',
    ]);

    const title = pageName === 'errorPage' ? `Error - ${brandName}` : (content.windowTitle || brandName);

    const isIndexable = useIsIndexable(canonicalUrl, pageName);

    return (
        <>
            <Head>
                <title>{title}</title>
                {(isIndexable && !showNoResults) && (
                    <link
                        rel="canonical"
                        href={canonicalUrl}
                    />
                )}
                <link
                    rel="image_src" // eslint-disable-line react/no-invalid-html-attribute -- this is valid for socials
                    href={content.openGraphImage || 'https://www.autotrader.com/resources/img/components/open-graph_autotrader_new.png'}
                />
                <link
                    rel="dns-prefetch"
                    href={'https://' + inventoryImageUrl}
                />
                {(showNoResults || !isIndexable) && (
                    <meta
                        name="robots"
                        content="noindex"
                    />
                )}
                <meta
                    name="description"
                    content={content.metaDescription || ''}
                />
                <meta
                    name="p:domain_verify"
                    content="315d28475397cff9ca9875c3d437ee5b"
                />
                <meta
                    name="referrer"
                    content="unsafe-url"
                />

                <script>
                    {'function atcCreateAd(o){atcCreateAd.adList=[].concat(atcCreateAd.adList||[],o)}'}
                </script>

                {renderFavicon(brandFavicon)}
                {renderManifestFile({ brandName })}
            </Head>
            <FontPreloads />
            <OptimizelySnippetLoader
                query={query}
                url={currentUrl}
            />
            <PixallPreload />
            <CreditAppScriptLoader />
            <FacebookMetaTags />

            {renderOpenGraphMetaTags({ brandName, brandOGImage, brandDefaultMetaDescription, content, currentUrl: canonicalUrl || currentUrl, openGraphImage })}
            {
                content.keywords && (
                    <Head>
                        <meta
                            name="keywords"
                            content={content.keywords || ''}
                        />
                    </Head>
                )
            }
            {
                content.metaRobots && (
                    <Head>
                        <meta
                            name="robots"
                            content={content.metaRobots || ''}
                        />
                    </Head>
                )
            }
            {
                content.mobileAlternateUrl && (
                    <Head>
                        <link
                            rel="alternate"
                            href={content.mobileAlternateUrl}
                        />
                    </Head>
                )
            }
            {
                content.alternateUrl && (
                    <Head>
                        <link
                            rel="alternate"
                            href={content.alternateUrl}
                        />
                    </Head>
                )
            }
            {
                content.rssAlternateUrl && (
                    <Head>
                        <link
                            rel="alternate"
                            type="application/rss+xml"
                            href={content.rssAlternateUrl}
                        />
                    </Head>
                )
            }
            {
                content.externalCSS && (
                    <Head>
                        <link
                            rel="stylesheet"
                            type="text/css"
                            href={content.externalCSS}
                        />
                    </Head>
                )
            }
            {
                isBrand(brands.FORD_BRAND) && pageName === FORD_LANDING && (
                    <Head>
                        <link
                            rel="preload"
                            as="style"
                            type="text/css"
                            onLoad="this.rel='stylesheet'"
                            href="/cars-for-sale/assets/fba/fordlanding-fontface.css"
                        />
                    </Head>
                )
            }
        </>
    );
}

export default React.memo(CommonHeadContainer, _isEqual);
